import { Container, Divider, Typography, Button, Grid2  } from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import e1 from './screenshots/expulsion1.png';
import e2 from './screenshots/expulsion2.png';
import e3 from './screenshots/expulsion3.png';
import e4 from './screenshots/expulsion4.png';
import e5 from './screenshots/expulsion5.png';
import e6 from './screenshots/expulsion6.png';
import e7 from './screenshots/expulsion7.png';
import e8 from './screenshots/expulsion8.png';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9727',
      contrastText: 'white',
    },
  },
  typography: {
    button: {
      fontFamily: ['Montserrat', 'serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
    },
  }
});

const screenshots = [e1, e2, e3, e4, e5, e6, e7, e8]

const Expulsion = () => {
    return <ThemeProvider theme={theme}>
        <Helmet>
            <title>Expulsion - YZee Games</title>
            <meta name="description" content="Expulsion is a fast-paced platformer about dodging and shooting." />
        </Helmet>
        <Container maxWidth="md">

            <Container sx={{paddingBottom:15, paddingTop:15}}>
                <Divider color='white'/>
                <Grid2 container justifyContent={'center'} alignItems={'center'}>
                    <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 55, maxWidth:'md'}} color='white'>Expulsion</Typography>
                </Grid2>
                <Divider color='white' sx={{marginBottom: 3}}/>

                <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                    <iframe
                        allowFullScreen
                        title="trailer"
                        style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        }}
                        src="https://www.youtube.com/embed/gnHXQQS5PMY"
                    ></iframe>
                </div>
            
                <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 26, marginTop: 3}} color='white'>A girl has been taken by the devil. A priest by the name of Garry has sent you on A quest to retrieve A scroll which will grant the girl’S return. dive into the depths as you make your way to the scroll fighting monsters with your trusty bow.</Typography>
                
                <Grid2 container justifyContent={'center'} alignItems={'center'}>
                    <Button sx={{marginTop:3, width:200, fontSize: 20, color:'#463c30'}} href={'https://www.newgrounds.com/portal/view/691004'} variant="contained">PLAY!</Button> 
                </Grid2>
                
            </Container>
        </Container>

    <Container sx={{backgroundColor:'white', minWidth:'100%', paddingBottom:25, paddingTop:15}}>
        <Container maxWidth="md">
            <Grid2 container direction={'column'} alignItems={'center'}>
                <Grid2 container>
                <Typography fontFamily={'Bahiana'} sx={{fontSize: 46.5}} color='#463c30'>Screenshots</Typography>
                </Grid2>

                <Grid2 container spacing={3} columns={{xs:12, sm:12, lg: 12}} >
                    {screenshots.map((i) => {
                        return <Grid2 size={{xs: 12, sm:12, md:6}} justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
                            <img alt={"screenshot1"} src={i} style={{width:'100%'}}></img>
                        </Grid2>
                    })}
                </Grid2>
            </Grid2>
        </Container>
    </Container>

  </ThemeProvider>
}

export default Expulsion;