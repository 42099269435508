import { Container, Divider, Typography, Grid2, useMediaQuery  } from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import bs1 from './screenshots/bs1.bmp';
import bs2 from './screenshots/bs2.bmp';
import bs3 from './screenshots/bs3.bmp';
import bs4 from './screenshots/bs4.bmp';
import bs5 from './screenshots/bs5.bmp';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9727',
      contrastText: 'white',
    },
  },
});

const screenshots = [bs1, bs2, bs3, bs4, bs5]

const Surge = () => {
    const isMob = useMediaQuery('(max-width:1000px)');

    return <ThemeProvider theme={theme}>
        <Helmet>
            <title>Boulder Surge - YZee Games</title>
            <meta name="description" content="Boulder Surge is a fast-paced dodger available on IOS. Dodge the falling boulders for as long as possible to increase your score!" />
        </Helmet>
        <Container maxWidth="md">

            <Container sx={{paddingBottom:15, paddingTop:15}}>


            <Divider color='white'/>
            <Grid2 container justifyContent={'center'} alignItems={'center'}>
                <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 55, maxWidth:'md'}} color='white'>Boulder Surge</Typography>
            </Grid2>
            <Divider color='white' sx={{marginBottom: 3}}/>

                <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                    <iframe
                        allowFullScreen
                        title="trailer"
                        style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        }}
                        src="https://www.youtube.com/embed/10ChGtocx_w"
                    ></iframe>
                </div>
        
            <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 26, marginTop: 3}} color='white'>The new addicting arcade dodger. Dodge the falling boulders for as long as possible to increase your score! A hardcore and standard mode are available offering a range of gameplay. Available on the AppStore for free.</Typography>
            
        </Container>
    </Container>

    <Container sx={{backgroundColor:'white', minWidth:'100%', paddingBottom:25, paddingTop:15}}>
        <Container maxWidth="md">
            <Grid2 container direction={'column'} alignItems={'center'}>
                <Grid2 container>
                <Typography fontFamily={'Bahiana'} sx={{fontSize:isMob ? 36.5 : 46.5}} color='#463c30'>Screenshots</Typography>
                </Grid2>

                <Grid2 container spacing={3} columns={{xs:1, sm:8, lg: 12}} >
                    {screenshots.map((i) => {
                        return <Grid2 size={{xs: 1, sm:4, md:4}} justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
                        <img alt={"screenshot1"} src={i} style={{width:'100%'}}></img>
                        </Grid2>
                    })}
                </Grid2>
            </Grid2>
        </Container>
    </Container>

  </ThemeProvider>
}

export default Surge;