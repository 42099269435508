import { Container, Typography, Grid2, Button, Divider, useMediaQuery, CardMedia } from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import background from './background.png'
import dsbanner from './deadspawnbanner.jpg';
import bsbanner from './bouldersurgebanner.jpg';
import './Bahiana.css';
import e1 from './screenshots/expulsion1.png';
import p1 from './screenshots/ps1.png';
import gb1 from './screenshots/gb2.png';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9727',
      contrastText: 'white',
    },
  },
  typography: {
    // fontFamily: ['Montserrat', 'serif'].join(','), Uncomment if you want all the typography to inherit this font.
    button: {
      fontFamily: ['Montserrat', 'serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
    },
  }
});

// https://www.klei.com/careers
// https://exok.com/games.html

function Home() {
  //const [image, setImage] = useState(0);

  const isMob = useMediaQuery('(max-width:899px)');
  const isMob2 = useMediaQuery('(max-width:1000px)');
 // const isMob2 = useMediaQuery('(max-width:1700px)');

  return (
      <div className='bg' style={{backgroundImage: `url(${background})`}}>
          <Helmet>
            <title>Home - YZee Games (Developing Top-Down Games)</title>
            <meta name="description" content="YZeeGames is an indie developer company working on top-down games for PC. Dead Spawn is YZeeGames' current project, coming to Steam soon." />
        </Helmet>
        <ThemeProvider theme={theme}>

          <Container maxWidth="md" sx={{paddingBottom:isMob ? 14 : 24, paddingTop:isMob2 && !isMob ? 18 : isMob? 10 : 20}}>

            <Typography align='left' fontFamily={'Bahiana'} sx={{fontSize: 60}} color='white'>Dead Spawn out now!</Typography>
        
            <Grid2 container spacing={6} columns={{xs:12, sm:12, lg: 12}}>
              <Grid2 size={{xs: 12, sm:8, md:8}} justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
                <CardMedia component="iframe" 
                  src="https://www.youtube.com/embed/y4_tgLmzTwQ"
                  sx={{ aspectRatio: "16/9"}}
                  allow="fullscreen" 
                />
              </Grid2>
            </Grid2>

            <div style={{marginTop:15}}>
              <Button href={'https://store.steampowered.com/app/721990/Dead_Spawn/'} sx={{width: 200, fontSize: 20, color:'#463c30'}} variant="contained">BUY</Button>
            </div>
          
          </Container>


          <Container sx={{backgroundColor:'white', minWidth:'100%', paddingBottom:25, paddingTop:15}}>
            <Container maxWidth="lg">

            <Divider/>
            <Grid2 container justifyContent={'center'} alignItems={'center'}>
              <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 55, maxWidth:'md'}} color='#463c30'>GAMES</Typography>
            </Grid2>
            <Divider/>

            <Grid2 container spacing={isMob ? 0 : 6} columns={{xs:12, sm:12, lg: 12}} sx={{mt:2, mb: isMob ? 2 : 0}}>
              <Grid2 size={{xs: 12, sm:12, md:6}} justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
                <img alt={"screenshot1"} style={{marginBottom:10, width:'100%'}} src={dsbanner}></img>
              </Grid2>

              <Grid2 size={{xs: 12, sm:12, md:6}}>
                <Typography align='left' fontFamily={'Bahiana'} sx={{fontSize: 32}} color='#463c30'>Dead Spawn (PC/STEAM)</Typography>
                <Typography align='left' fontFamily={'Bai Jamjuree'} sx={{fontSize: 18, mb:2}} color='#463c30'>You and a crew of 4 have crash landed on an unknown place to find you are not alone. Fight off waves of zombies with your friends or by yourself. Progress through waves receiving supply drops and upgrading your weapons.</Typography>
                <Grid2 sx={{marginBottom: isMob ? 0 : 2}}>              
                  <Button sx={{width: 100, mr:1, color:'#463c30'}} href={'https://store.steampowered.com/app/721990/Dead_Spawn/'} variant="contained">BUY</Button>
                  <Button sx={{width:100, color:'#463c30'}} href={'https://deadspawn.com/'} variant="contained">INFO</Button> 
                </Grid2>
              </Grid2>
            </Grid2>

            <Divider/>
            
            <Grid2 container spacing={isMob ? 0 : 6} columns={{xs:12, sm:12, lg: 12}} sx={{mt:2}}>
              <Grid2 size={{xs: 12, sm:12, md:6}}>
                <img alt={"screenshot3"} style={{marginBottom:10, width:'100%'}} src={bsbanner}></img>
              </Grid2>

              <Grid2 size={{xs: 12, sm:12, md:6}}>
                <Typography align='left' fontFamily={'Bahiana'} sx={{fontSize: 32}} color='#463c30'>Boulder Surge (IOS)</Typography>
                <Typography align='left' fontFamily={'Bai Jamjuree'} sx={{fontSize: 18, marginBottom: 2}} color='#463c30'>You and a crew of 4 have crash landed on an unknown place to find you are not alone. Fight off waves of zombies with your friends or by yourself. Progress through waves receiving supply drops and upgrading your weapons.</Typography>
                <Button sx={{mr:1, width:100, color:'#463c30'}} href="/boulder-surge" variant="contained">INFO</Button> 
              </Grid2>
            </Grid2>
            

            <Divider sx={{marginTop:6}}/>
            <Grid2 container justifyContent={'center'} alignItems={'center'}>
              <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 55, maxWidth:'md'}} color='#463c30'>Game Jams</Typography>
            </Grid2>
            <Divider/>

            <Grid2 container spacing={2} columns={{xs:1, sm:1, lg: 12}} sx={{mt:2, }}>

              <Grid2 className="grid-item"  size={{xs: 1, sm:6, md:6}} sx={{position: 'relative'}}>
                <a href="/expulsion">
                  <img className='image' alt={"screenshot3"} style={{width:'100%'}} src={e1}></img>              
                  <Typography fontFamily={'Bahiana'} align='center' className="overlay-text"
                    sx={{ fontSize: 80, color: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', visibility: isMob ? 'visible' : 'hidden', 
                      transition: 'visibility 0.3s, opacity 0.3s', opacity: isMob ? 1 : 0}}
                  >{"Expulsion"}</Typography>
                  <Typography fontFamily={'Bahiana'} align='center' className="overlay-text"
                    sx={{ fontSize: 25, color: 'white', position: 'absolute', bottom: '0', right: '1%', visibility: isMob ? 'visible' : 'hidden', 
                      transition: 'visibility 0.3s, opacity 0.3s', opacity: isMob ? 1 : 0}}
                  >{"3/10/16"}</Typography>
                  <Typography fontFamily={'Bahiana'} align='center' className="overlay-text"
                    sx={{ fontSize: 25, color: 'white', position: 'absolute', bottom: '0', left: '1%', visibility: isMob ? 'visible' : 'hidden', 
                      transition: 'visibility 0.3s, opacity 0.3s', opacity: isMob ? 1 : 0}}
                  >{"Weirder Stuff"}</Typography>
                </a>
              </Grid2>

              <Grid2 className="grid-item" size={{xs: 1, sm:6, md:6}} sx={{position: 'relative'}}>
                <a href="/pixel-sprint">
                  <img className='image' alt={"screenshot3"} style={{width:'100%'}} src={p1}></img>
                  <Typography fontFamily={'Bahiana'} align='center' className="overlay-text"
                    sx={{ lineHeight: 0.9, fontSize: 80, color: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', visibility: isMob ? 'visible' : 'hidden', 
                      transition: 'visibility 0.3s, opacity 0.3s', opacity: isMob ? 1 : 0}}
                  >{"Pixel Sprint"}</Typography>
                  <Typography fontFamily={'Bahiana'} align='center' className="overlay-text"
                    sx={{ fontSize: 25, color: 'white', position: 'absolute', bottom: '0', right: '1%', visibility: isMob ? 'visible' : 'hidden', 
                      transition: 'visibility 0.3s, opacity 0.3s', opacity: isMob ? 1 : 0}}
                  >{"17/7/17"}</Typography>
                  <Typography fontFamily={'Bahiana'} align='center' className="overlay-text"
                    sx={{ fontSize: 25, color: 'white', position: 'absolute', bottom: '0', left: '1%', visibility: isMob ? 'visible' : 'hidden', 
                      transition: 'visibility 0.3s, opacity 0.3s', opacity: isMob ? 1 : 0}}
                  >{"Game Maker’s Toolkit Jam"}</Typography>
                </a>
              </Grid2>

              <Grid2 className="grid-item" size={{xs: 1, sm:6, md:6}} sx={{position: 'relative'}}>
                <a href="/galactic-break">
                  <img className='image' alt={"screenshot3"} style={{width:'100%'}} src={gb1}></img>
                  <Typography fontFamily={'Bahiana'} align='center' className="overlay-text"
                    sx={{ lineHeight: 0.9, fontSize: 80, color: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', visibility: isMob ? 'visible' : 'hidden', 
                      transition: 'visibility 0.3s, opacity 0.3s', opacity: isMob ? 1 : 0}}
                  >{"Galactic Break"}</Typography>
                  <Typography fontFamily={'Bahiana'} align='center' className="overlay-text"
                    sx={{ fontSize: 25, color: 'white', position: 'absolute', bottom: '0', right: '1%', visibility: isMob ? 'visible' : 'hidden', 
                      transition: 'visibility 0.3s, opacity 0.3s', opacity: isMob ? 1 : 0}}
                  >{"23/10/17"}</Typography>
                  <Typography fontFamily={'Bahiana'} align='center' className="overlay-text"
                    sx={{ fontSize: 25, color: 'white', position: 'absolute', bottom: '0', left: '1%', visibility: isMob ? 'visible' : 'hidden', 
                      transition: 'visibility 0.3s, opacity 0.3s', opacity: isMob ? 1 : 0}}
                  >{"Weirder Stuff 2"}</Typography>
                </a>
              </Grid2>

            </Grid2>
        
            </Container>
          </Container>

        </ThemeProvider>
    </div>
  );
}

export default Home;
